import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Formulario from "../components/Formulario"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformasBano() {

  gsap.registerPlugin(ScrollTrigger);

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let scrollTriggers = [];
    scrollTriggers = animateReveal(revealRefs.current);
    return () => {
      scrollTriggers.forEach((t) => t.kill(true));
    };
  }, []);

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  // end gsap

  return (
    <Layout>
      <Helmet
        title="Empresa de reformas | Pide presupuesto ya"
        meta={[
          { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
        ]}
      />
      <div className="bg-[url('https://reformas.top/background/reformas-banos-pequenos.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
        <div className="container flex flex-col xl:flex-row justify-between py-5">
          <div className="flex items-center justify-center 2xl:px-10">
            <h1
              className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10">
              Reformas de baños pequeños en <strong>en Madrid
                ciudad</strong>
            </h1>
          </div>
          <div>
            <Formulario />
          </div>
        </div>
      </div>
      <section 
        style={{ "background": "transparent linear-gradient(0deg, #f2f4f9 0%, #FFFFFF00 30%) 0% 0% no-repeat padding-box" }}>
        <div className=" container py-20 ">
          <div className="max-w-4xl text-center mx-auto mb-10">
            <p className="text-text text-xl gs_reveal">El baño es ese lugar de la casa en que nos relajamos y dejamos todo el
              estrés de
              la rutina diaria, por eso este debe estar impecable y bien diseñado para poder disfrutarlo al máximo. Las
              últimas tendencias en interiorismos para baños pequeños están aquí, así que si buscas una reforma de
              vanguardia, puedes contar con nosotros. Somos Reformas Top, los verdaderos profesionales en reformas y todo
              tipo de obras para el hogar y locales comerciales.
            </p>
          </div>
          <div className="container">
            <h2 className="mt-6 text-5xl text-black font-bold text-center mb-10 gs_reveal">Reformas de Baños
              <br/><strong>Precios</strong>
            </h2>
            <div className="mx-auto max-w-6xl  mb-20 text-center">
            <StaticImage className="rounded-3xl shadow-md mb-5 gs_reveal" src="../images/reformas-de-baños-precios.webp" alt="Reformas de Cocinas Pequeñas" />
              <p className="mb-5 gs_reveal">
                ¿Estás en busca de un proyecto de reforma para tu baño? Sabemos que te preocupa el precio, pero tampoco
                quieres abandonar la calidad. Si este es tu caso, nosotros somos tu mejor alternativa. Recuerda que los
                precios pueden variar mucho de acuerdo al tamaño de la obra y los materiales que se requieren. </p>
              <p className="gs_reveal">En este
                sentido, queda claro que no existe un presupuesto estándar para toda reforma de baños, sin embargo, si
                deseas recibir asesoramiento y un importe estimado, no dudes en llamarnos cuanto antes.
              </p>
            </div>
            <div className=" grid grid-cols-1 lg:grid-cols-2">
              <div className="lg:pr-20 flex flex-col justify-center">
                <p className=" font-semibold text-2xl gs_reveal gs_reveal_fromLeft">Somos experiencia y trayectoria viva...
                </p>
                <p className="gs_reveal gs_reveal_fromLeft">Por eso podemos ofrecer garantías de calidad por nuestro trabajo.
                  Diariamente estamos innovando y
                  estableciendo tendencias en materia de interiorismo, por eso hoy por hoy, somos líderes del mercado.
                </p>
                <p className="gs_reveal gs_reveal_fromLeft">Nuestros precios oscilan entre los 3500 y 15000 euros, teniendo en
                  cuenta los diferentes factores que
                  influyen en el precio final de cada obra. Si quieres realizar tu reforma y tu presupuesto es corto,
                  contáctanos cuanto antes, haremos todo lo posible por conseguir los materiales más económicos para
                  ofrecerte un presupuesto que se adapte a tu bolsillo.
                </p>
                <p className="gs_reveal gs_reveal_fromLeft">Somos profesionales y hacemos todo tipo de reformas
                  integrales en, así que déjate ayudar con tu proyecto y asegúrate con nosotros, una obra
                  magnífica.
                </p>
              </div>
              <div>
              <StaticImage src="../images/reformas-top-banos.webp" alt="ReformasTop de Baños" className="gs_reveal gs_reveal_fromRight w-full"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container my-20">
        <div className=" max-w-5xl mx-auto">
          <h2 className="mt-6 text-5xl text-black font-bold text-center mb-10 gs_reveal">Reformas de Baños</h2>
          <p className="text-center gs_reveal">Las reformas de baños son una de nuestras especialidades, por eso nos
            mantenemos
            constantemente innovando y
            aplicando nuevas estrategias de diseño e interiorismo para plasmar los resultados más alucinantes. A
            continuación, te dejamos algunos de nuestros fantásticos trabajos:</p>
            <StaticImage src="../images/reformas-de-baños.webp" alt="Reformas de Baños" />
            <p className="text-center gs_reveal">Nosotros nos inspiramos de todo lo que vemos, y toda esa inspiración la
              aplicamos
              enseguida para sostener nuestro ritmo de innovación en cuanto a estética se refiere. Tenemos aún muchas ideas
              por plasmar y la próxima puede ser aplicada a tu proyecto.</p>
            <p className="text-center gs_reveal"> Así como en las destacadas reformas de casas que
              hacemos, sin duda somos los número uno y si te interesa esto también, ingresa en
              https://reformas.top/casas/. No te lo pienses más y ponte en contacto con nosotros, alguna de estas
              ideas podría materializarse en tu hogar.</p>
        </div>
      </section>
      <div className="container mt-6">
        <div>
          <div className=" max-w-4xl mx-auto">
            <h2 className=" text-4xl lg:text-5xl text-black font-bold text-center mb-10 gs_reveal">Recomendaciones antes de
              reformar tu
              baño</h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className="flex flex-col items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs} 
                  className="ico-service block icon-select"></span></div>
                <p className="text-xl text-center gs_reveal_fromLeft gs_reveal">Selecciona una empresa de reformas confiable y
                  experta.</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className="flex flex-col items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs} 
                  className="ico-service block icon-calendar"></span></div>
                <p className="text-xl text-cente gs_revealr">Establece los plazos de entrega y tu presupuesto.</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className="flex flex-col items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs} 
                  className="ico-service block icon-construir"></span></div>
                <p className="text-xl text-center gs_reveal gs_reveal_fromRight">Estudia con los expertos los aspectos
                  estructurales y de servicios a
                  renovar para evitar futuras sorpresas.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}